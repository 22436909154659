import { combineReducers } from 'redux';

import authReducer from './auth/authReducer';
import commonReducer from './common/commonReducer';
import exchangeSportsReducer from './exchangeSports/exchangeSportsReducer';
import exchBetslipReducer from './exchBetslip/exchBetslipReducer';
import indianCasinoReducer from './casino/CasinoGameReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  exchangeSports: exchangeSportsReducer,
  indianCasino: indianCasinoReducer,
  exchBetslip: exchBetslipReducer,
});

export default rootReducer;
