// export const BRAND_NAME = 'sky11';
// export const BRAND_DOMAIN = 'sky11.com';

export const OPERATORID = 'HypexDemo';
// export const PROVIDER_ID = 'Betfair';

export const BRAND_NAME = 'Cric8';
export const BRAND_DOMAIN = 'cric8.io';
export const BRAND_DOMAIN_SKIN_URL = 'cric8.io';
export const PROVIDER = 'BetFair';
export const PROVIDER_ID = 'BetFair';
export const BACKEND_DOMAIN = 'mnto9.com';
