import React from 'react';
import './LoginPage.scss';
import LoginForm from '../../components/LoginForm/LoginForm';
import Card from '@material-ui/core/Card';
import { IonApp, IonContent } from '@ionic/react';
import cardImg from '../../assets/images/brand/bgframe.png';
import cardImgMob from '../../assets/images/brand/cardImg.png';
import { useHistory, NavLink } from 'react-router-dom';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
const Login: React.FC = () => {
  let history = useHistory();
  return (
    <>
      <IonApp>
        <IonContent className="background">
          <div className="web-view">
            <Card className="login-card">
              <div className="card-left-side wob-view">
                <div className="go-corner"></div>
                <img src={cardImg} className="card-img" />
                <div className="go-corner-bottom"></div>
              </div>
              <div className="card-right-side">
                <LoginForm />
              </div>
            </Card>
          </div>
          <div className="mob-view mobile-view-login">
            <div
              className="login-card-mob"
              onClick={() => history.push('/home')}
            >
              <div className="go-corner">
                <div className='go-arrow'>
                <HighlightOffIcon fontSize="large" />
                </div>
              </div>
              <img src={cardImgMob} className="card-img" />
              <div className="go-corner-bottom"></div>
            </div>
            <div className="login-ctn-mob">
              <LoginForm />
            </div>
          </div>
        </IonContent>
      </IonApp>
    </>
  );
};

export default Login;
