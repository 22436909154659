import kabaddi from '../assets/images/sportsbook/icons/kabaddi.svg';
import volleyball from '../assets/images/sportsbook/icons/volleyball.svg';
import greyhound from '../assets/images/sportsbook/icons/greyhoundracing.svg';
import horseracing from '../assets/images/sportsbook/icons/hourseracing.svg';
export const EXCHANGE_EVENT_TYPES = [
  { id: '4', name: 'Cricket', slug: 'cricket' },
  { id: '1', name: 'Football', slug: 'football' },
  { id: '2', name: 'Tennis', slug: 'tennis' },
  // { id: '14', name: 'Kabaddi', slug: 'kabaddi' },
  { id: '7', name: 'HorseRacing', slug: 'horseracing' },
  {
    id: '4339',
    name: 'GreyHound',
    slug: 'greyhound',
  },
  {
    id: '7522',
    name: 'basketball',
    slug: 'basketball',
    enabled: true,
  },
  {
    id: '7511',
    name: 'baseball',
    slug: 'baseball',
    enabled: true,
  },
];

export const EXCH_SPORTS_MAP = {
  tennis: '2',
  football: '1',
  soccer: '1',
  cricket: '4',
  kabaddi: '14',
  horseracing: '7',
  greyhound: '4339',
  basketball: '7522',
  baseball: '7511',
};
export const COMING_SOON_EVENT_TYPES = [
  { id: '', name: 'volleyball', img: volleyball, slug: '/up/volleyball' },
  {
    id: '',
    name: 'kabaddi',
    slug: '/up/kabaddi',
    img: kabaddi,
    gameName: 'Kabaddi',
    gameCode: 'ids_kabaddiraiders',
    gameId: '200300',
    platformId: 'desktop',
    provider: 'DC',
    subProviderName: 'Indi Slots',
  },

  {
    gameId: '200971',
    provider: 'DC',
    name: 'HorseRacing',
    slug: '/up/horseracing',
    gameName: 'Horse Racing',
    subProviderName: 'Kiron',
    gameCode: '',
    img: horseracing,
  },
  {
    gameId: '200972',
    gameName: 'Greyhound Racing',
    name: 'GreyhoundRacing',
    slug: '/up/greyhound-racing',
    provider: 'DC',
    subProviderName: 'Kiron',
    gameCode: '',
    img: greyhound,
  },
];

const EXCHANGE_EVENT_TYPES_GAMES = [
  {
    gameId: 7177,
    gameName: 'Table tennis',
    gameCode: 'krn_tabletennis',
    provider: 'Kiron',
    name: 'Table Tennis',
  },

  {
    gameId: 7187,
    gameName: 'Golf',
    gameCode: 'krn_golf',
    provider: 'Kiron',
    name: 'Golf',
  },

  {
    gameId: 631,
    gameName: 'Kabaddi',
    gameCode: 'ids_kabaddiraiders',
    provider: 'Kabaddi Raiders',
    name: 'Kabaddi',
  },
  {
    gameId: 7175,
    gameName: 'Motor racing (Max Car)',
    gameCode: 'krn_motorracingmaxcar',
    provider: 'Motor racing (Max Car)',
    name: 'Motor Race',
  },
  {
    gameId: 13844,
    gameName: 'Ice Hockey League Round',
    gameCode: 'krn_icehockeyleagueround',
    provider: 'Kiron',
    name: 'Ice Hockey',
  },

  {
    gameId: 6984,
    gameName: 'Golden Race',
    gameCode: 'gdr_goldenrace',
    provider: 'Golden Race',
    name: 'Greyhound Racing',
  },
  {
    gameId: 7173,
    gameName: 'Dogs (Platinum Hounds)',
    gameCode: 'krn_dogsplatinumhounds',
    provider: 'Kiron',
    name: 'Horse Racing',
  },
];
